.menu {
  &__bg-video {
    position: relative;

    & > div > video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }

  &__sections {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &-section {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include hover-cursor;
      @include zoom;
      transition: all 0.3s ease-in-out;
      margin: 30px;

      & > img {
        border-radius: 50%;
      }

      & > p {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin: 1.5rem 0;
      }
    }
  }
}
