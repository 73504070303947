.button {
  text-decoration: none;
  margin: 10px 20px;
  padding: 7px 30px;
  border: 2px solid white;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;

  &__transparent {
    color: white;

    &:hover {
      background-color: white;
      color: black;
    }
  }

  &__white {
    color: black;
    background-color: white;

    &:hover {
      background-color: transparent;
      color: white;
    }
  }
}

.button-see-more {
  font-style: italic;
  text-decoration: underline;
  color: white;
  font-size: 20px;
}
