.footer {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  padding-bottom: 20px;

  & > p {
    margin: 25px 0px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  & > div {
    & > a > img {
      height: 45px;
      @include hover-cursor;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
