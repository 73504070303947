.page {
  padding-top: 50px;

  &__bg-video {
    position: relative;

    & > div > video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }
}
