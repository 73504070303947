@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin centerX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin align-left {
  position: absolute;
  left: 0;
}

@mixin align-right {
  position: absolute;
  right: 0;
}

@mixin remove-button-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin hover-cursor {
  &:hover {
    cursor: pointer;
  }
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin elevate {
  transition: all 0.2s ease;
  &:hover {
    margin-top: -1rem;
  }
}

@mixin zoom {
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
}

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin cover-bg-img {
  background-size: 100% 100%;
}

@mixin interface-container {
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 1;
  transition: opacity 1s ease;
  position: absolute;
  top: 2rem;
}

@mixin box-spell($gap) {
  clip-path: $clipHexa;
  position: relative;
  @include hover-cursor;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    /* initial ratio of 1:1*/
  }

  &:not(:last-child) {
    margin-right: $gap;
  }

  & > div {
    @include hover-cursor;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    overflow: hidden;

    clip-path: $clipHexaSpell;

    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    overflow: hidden;

    line-height: 100%;
  }
}

//MEDIA QUERY MANAGER
/*
0 - 60rem:	Phone (phone)
600 - 90rem:	Tablet portrait (tab-port)
900 - 120rem:	Tablet landscape (tab-land)
[1200 - 180rem] is where our styles apply
180rem + :	Big desktop (big-desktop)

ORDER: Base + Typography > general layout + grid > page layout > component

1em = 16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //60rem
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //90rem
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //120rem
  }

  @if $breakpoint == labtop-max {
    @media only screen and (max-width: 93.75em) {
      @content;
    } //1400
  }

  @if $breakpoint == labtop-min {
    @media only screen and (min-width: 93.75em) {
      @content;
    } //1400
  }

  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800
  }
}

@mixin scrollbars(
  $size: 10px,
  $foreground-color: $secondary,
  $background-color: $primary-dark
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;
}
