.hero {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 920px;
  position: relative;

  &__bg-video {
    & > div > video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > img {
      margin-top: 100px;
    }

    & > h1 {
      font-size: 55px;
      margin: 5px;
    }
  }

  &__see-more {
    margin-top: 50px;
    @include hover-cursor;
    @include zoom;

    & > a {
      display: flex;
      flex-direction: column;
      & > svg {
        margin-top: 20px;
        height: 30px;
      }
    }
  }
}
