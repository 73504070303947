.carousel {
  margin: 50px 0;
  display: flex;
  overflow-x: hidden;

  &__item {
    cursor: pointer;
    & > img {
      width: 100%;
    }

    &--video {
      position: relative;
      display: inline-block;
      cursor: pointer;

      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        height: 15%;
        transition: all 0.2s ease;
      }

      &:hover > svg {
        color: #c4c4c4;
      }
    }
  }
}

.modal-content {
  background-color: transparent;

  & > div > img {
    border-radius: 6px;
  }
}
