.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-around;
  margin-top: 10px;

  z-index: 50;
  --above-all-in-container: 10000;

  & > ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
